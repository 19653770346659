import React, { ComponentType } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ReactSlider from 'react-slick'
import { gatsbyImgDataToHTMLImage, ImageSrc } from 'src/components/Image'
import { css } from 'src/style/css'
import { SerializedStyles, useTheme } from '@emotion/react'
import icons from 'src/icons'

export type SliderProps = {
    autoplay?: boolean
    className?: string
}
// We have to remove pascalCase attributes
const { AngleRight, AngleLeft } = icons
const NextArrow = ({ currentSlide, slideCount, ...rest }: any) => (
    <AngleRight {...rest} />
)
const PrevArrow = ({ currentSlide, slideCount, ...rest }: any) => (
    <AngleLeft {...rest} />
)
export const Slider: ComponentType<SliderProps> = ({
    className,
    children,
    autoplay = true
}) => {
    const th = useTheme()
    return (
        <div
            css={css`
                display: flex;
                justify-content: center;
                width: 100%;
            `}
        >
            <ReactSlider
                dots
                infinite
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                autoplay={autoplay}
                autoplaySpeed={8000}
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
                css={css`
                    width: calc(100% - 80px);
                    .slick-next,
                    .slick-prev {
                        color: ${th.colors.primary};
                        width: 2rem;
                        height: 2rem;
                    }
                    .slick-next {
                        right: -37px;
                    }
                    .slick-prev {
                        left: -37px;
                    }

                    @media (max-width: ${th.mediaQuery.sm}) {
                        width: calc(100% - 40px);
                        .slick-next {
                            right: -30px;
                        }
                        .slick-prev {
                            left: -30px;
                        }
                    }
                `}
            >
                {children}
            </ReactSlider>
        </div>
    )
}

export type ImageGalleryProps = {
    images: {
        src: ImageSrc
        alt: string
    }[]
    className?: string
    imageStyles?: SerializedStyles
}

export const ImageGallery: ComponentType<ImageGalleryProps> = ({
    images,
    className,
    imageStyles
}) => {
    const th = useTheme()
    return (
        <Slider className={className}>
            {images.map((di, idx) => {
                const imageData = gatsbyImgDataToHTMLImage(di.src)
                return (
                    <img
                        key={idx}
                        src={imageData.src}
                        sizes={imageData.sizes}
                        srcSet={imageData.srcSet}
                        alt={di.alt}
                        css={[
                            css`
                                height: 500px;
                                object-fit: cover;
                                @media (max-width: ${th.mediaQuery.md}) {
                                    height: 400px;
                                }
                                @media (max-width: ${th.mediaQuery.sm}) {
                                    height: 200px;
                                }
                            `,
                            imageStyles
                        ]}
                    />
                )
            })}
        </Slider>
    )
}
